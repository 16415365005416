import * as routePaths from "./RouteConstant";
import {
  RouteObject,
} from "react-router-dom";
import React, { Suspense, useContext } from "react";
import { AppContext } from "./App";
import { Layout } from "./shared/Layout";

const PageNotFound = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.PageNotFound,
  }))
);

const Dashboard = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.Dashboard,
  }))
);

const Profile = React.lazy(() => {
  return import("./pages").then((module) => ({
    default: module.Profile,
  }));
});

const ClientAppointmentListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ClientAppointmentListPage,
  }))
);

const ClientAppointmentViewPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ClientAppointmentViewPage,
  }))
);
const DocumentResourceListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.DocumentResourceListPage,
  }))
);
const DocumentResourceAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.DocumentResourceAddPage,
  }))
);
const DocumentResourceEditPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.DocumentResouceEditPage,
  }))
);

const ClientCasesListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ClientCasesListPage,
  }))
);

const ClientCasesViewPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ClientCasesViewPage,
  }))
);

const AuthRoutes = ({ component }: any) => {
  const { state } = useContext<any>(AppContext);
  const isLoading = state?.app_loading;

  if (isLoading) return null;

  return (
    <>
      {!isLoading ? (
        <Layout>
          <Suspense fallback={"Loading"}>{component}</Suspense>
        </Layout>
      ) : null}
    </>
  );
};

export const routes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: routePaths.DASHBOARD,
        element: <AuthRoutes component={<Dashboard />} />,
      },
      {
        path: routePaths.PROFILE,
        element: <AuthRoutes component={<Profile />} />,
      },
      {
        path: routePaths.CLIENT_APPOINTMENTS,
        element: <AuthRoutes component={<ClientAppointmentListPage />} />,
      },
      {
        path: routePaths.CLIENT_VIEW_APPOINTMENT,
        element: <AuthRoutes component={<ClientAppointmentViewPage />} />,
      },
      {
        path: routePaths.CLIENT_DOCUMENTS_ADD,
        element: (
          <AuthRoutes component={<DocumentResourceAddPage/>} />
        ),
      },
      {
        path: routePaths.CLIENT_DOCUMENTS_EDIT,
        element: (
          <AuthRoutes component={<DocumentResourceEditPage/>} />
        ),
      },
      {
        path: routePaths.CLIENT_DOCUMENTS_LIST,
        element: (
          <AuthRoutes component={<DocumentResourceListPage/>} />
        ),
      },
      {
        path: routePaths.CLIENT_CASES_LIST,
        element: <AuthRoutes component={<ClientCasesListPage />} />,
      },
      {
        path: routePaths.CLIENT_CASES_VIEW,
        element: <AuthRoutes component={<ClientCasesViewPage />} />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={"Loading"}>
        <PageNotFound />
      </Suspense>
    ),
  },
];
