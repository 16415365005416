import { Box } from "@mui/material";
import type { ReactNode } from "react";
import React from "react";

interface IComponentWrapper {
  children: ReactNode;
  sidebar: ReactNode;
  header?: ReactNode;
}

export const ComponentWrapper: React.FC<IComponentWrapper> = ({
  children,
  sidebar,
  header
}: any) => {
  return (
    <Box height="100vh" p={2.5} display="flex" sx={{ background: '#F8F8F8' }}>
      {sidebar}
      <Box flexGrow={1} overflow="auto">
        {header}
        <Box py={header ? 3 : 0} pl={header ? 3 : 2.5}>{children}</Box>
      </Box>
    </Box>
  );
};
