import { Icon } from "@lipihipi/rtc-ui-components";
import * as routePaths from "../../RouteConstant";
import { SITE_URL } from "../../constant";


export const MenuArr = [
  {
    id: "0",
    data: [
       {
        id: "0.0",
        icon: Icon.Dashboard,
        text: "Dashboard",
        active: true,
        link: routePaths.DASHBOARD,
      },
      {
        id: "0.1",
        icon: Icon.SProfile,
        text: "Profile",
        active: true,
        link: routePaths.PROFILE,
      },
      {
        id: "0.2",
        icon: Icon.Case,
        text: "Manage Cases",
        active: true,
        link: routePaths.CLIENT_CASES_LIST,
      },
      {
        id: "0.3",
        icon: Icon.Appointment,
        text: "Appointments",
        active: true,
        link: routePaths.CLIENT_APPOINTMENTS.replace(":id",''),
      },
      {
        id: "0.4",
        icon: Icon.Document,
        text: "Document Template",
        active: true,
        link: routePaths.CLIENT_DOCUMENTS_LIST,
      },

      {
        id: "0.5",
        icon: Icon.Lawyer,
        text: "Search Lawyers",
        active: true,
        link: `${SITE_URL}/lawyers`,
        external: true,
      },
      {
        id: "0.6",
        icon: Icon.Knowledge,
        text: "Knowledge Bank",
        active: true,
        link: `${SITE_URL}/knowledge-bank`,
        external: true,
      },
      {
        id: "0.7",
        icon: Icon.FreeDocTemplate,
        text: "Free Doc Template",
        active: true,
        link: `${SITE_URL}/legal-documents`,
        external: true,
      },
     
      {
        id: "0.8",
        icon: Icon.AboutUs,
        text: "About Us",
        active: true,
        link: `${SITE_URL}/about-us`,
        external: true,
      },
    ],
  },
];
