import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import RtcAPI from "@lipihipi/rtc-client-sdk";

if (process.env.REACT_APP_ENV === "uat") {
  RtcAPI.setSiteUrl("https://uat.api.restthecase.com/api/v1");
}else if (process.env.REACT_APP_ENV === "stage") {
  RtcAPI.setSiteUrl("https://stage.api.restthecase.com/api/v1");
} else {
  RtcAPI.setSiteUrl("https://api.restthecase.com/api/v1");
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// RtcAPI.config.get().then(({ data }: any) => {
// return root.render(
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  // </React.StrictMode>
);
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
